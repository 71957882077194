import axios from 'axios'

export default function getApiAtletas() {
	const vencedoresAno = document.querySelectorAll('[data-ano-header]')
	const bodyPage = document.querySelector('.render-atletas-ano-wrapper')



	vencedoresAno.forEach(ano => {

		ano.addEventListener('click', (e) => {
			if (e.currentTarget.dataset.ajax) {


				const svgLoad = `			<svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:transparent;display:block;" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<circle cx="50" cy="50" r="32" stroke-width="8" stroke="#efbb6e" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
							<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
						</circle>
					</svg>`

				bodyPage.innerHTML = svgLoad

				svgLoad

				axios
					.get(`/api/atletas/${ano.dataset.anoHeader}`)
					.then((data) => renderAtletas(data.data))
					.catch((e) => console.log(e))

			} else {
				bodyPage.innerHTML = ''
			}
		})
	})

	const logData = (data) => {
		data.forEach(item => {
			console.log(
				'Id:', item.id,
				'Nome:', item.nome,
				'Descrição categoria:', item.categoria,
				'Categoria:', item.tipo_votacao,
			);
		})
	}

	const renderAtletas = (data) => {


		const atletasAno = data.map((atleta, index) => {
			console.log(atleta, atleta.atletas)
			if (!atleta.atletas) {
				return
			}

			return `
							<div class="mb-64" js-anima data-anima="custom-down" data-duration="1.1" data-value="800">
							<div class="container">
									<div class="flex flex-col justify-center items-center mb-[22px] sm:justify-normal sm:items-start">
											<div class=" w-[60px] h-[8px] block bg-cor-1 opacity-90 mb-[15px]"></div>
											<h1 class="flex gap-8 text-title-1 text-cor-2 !uppercase sm:flex-col">
													${atleta.nome}
											</h1>
									</div>
							</div>
			
							
							<div class="relative">
									<img loading="lazy" class="w-full absolute h-[234px] bottom-[81px] lg:bottom-[77px] z-[-1] md:h-fit" src="${window.static_path}img/banners/banner-categoria-atleta-novo.webp" alt="">
									
									<swiper-container class="mySwiper swiperCustomCategoriaTecnica overflow-hidden" space-between="30" slides-per-view="auto" centered-slides="true">

									${atleta.atletas
					?.map(i => {
						return `
												<swiper-slide class="w-fit">
														<div class="relative">
																<div class="gradient-swiper w-full h-full absolute inset-0"></div>
																
																<img loading="lazy" class="w-[288px] h-[313px] object-cover mb-[21px]" src="${i.atleta.foto}" alt="">
														</div>
														<div class="flex flex-col items-center">
																<h1 class="text-title-1 text-cor-2 !uppercase !text-3xl">${i.atleta.nome}</h1>
																<p class="text-headline-3 text-cor-1 !font-medium">${i.categoria}</p>
														</div>
												</swiper-slide>
											`
					}).join('')}
			
										
																
									</swiper-container>
			
									<div class="w-full absolute top-[40%] z-[2]">
											<div class="w-full flex justify-between px-[83px] sm:px-16">
													<svg class="w-20 h-[30px] swiper-categoria-tecnica-prev swiper-button-disabled">
															<use xlink:href="#icon_arrow_left_yellow"></use>
													</svg>
									
													<svg class="w-20 h-[30px] swiper-categoria-tecnica-next">
															<use xlink:href="#icon_arrow_right_yellow"></use>
													</svg>
											</div>
									</div>
			
									<div class="absolute z-[1] right-0 top-0 w-[288px] h-[313px] gradient-swiper-right md:w-[144px] sm:hidden"></div>
							</div>
					</div>
					`

		}).join('')
		bodyPage.innerHTML = atletasAno

		//console.log(`Atletas ${data.ano}:`, data.nome);
	}


	console.log('arquivo get_api_atletas')
	if (vencedoresAno && vencedoresAno.length > 0) {

		console.log('aaaaaa', vencedoresAno[0])
		vencedoresAno[0].click()
	}

	// axios
	//     .get('/api/atletas/2023')
	//     .then((data) => logData(data.data))
	//     .catch((e) => console.log(e))
}
